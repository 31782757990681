import type { IConfigCatClient } from "configcat-js-ssr"
import * as configcat from "configcat-js-ssr"

export default defineNuxtPlugin({
  name: "ConfigCat",
  async setup() {
    const { public: { configCatApiKey } } = useRuntimeConfig()
    const logger = configcat.createConsoleLogger(configcat.LogLevel.Off)

    // Create ConfigCat client
    const configCatClient: IConfigCatClient = configcat.getClient(
      configCatApiKey,
      configcat.PollingMode.LazyLoad,
      {
        dataGovernance: configcat.DataGovernance.EuOnly,
        logger,
        cacheTimeToLiveSeconds: 600,
      },
    )

    return {
      provide: {
        configCat: configCatClient,
      },
    }
  },
})
