// part of nuxt
import type { $Fetch, NitroFetchRequest } from "nitropack"

export function searchAndFiltersRepository<T>(fetch: $Fetch<T, NitroFetchRequest>) {
  return {
    /**
     * Returns the scoped typesense API key
     * @param tenantId targeted tenant
     * @param vanity targeted vanity
     * @returns
     */
    async getTypesenseApiKey(tenantId: string, vanity: string): Promise<string> {
      return fetch<string>(`${UrlConfig.PATH_BASE_OFFER_SERVICE}/search/api-key`, {
        headers: {
          "Accept": "application/json",
          "X-Tenant-Id": tenantId,
        },
        params: {
          filter: `backoffice_vanity:${vanity}`,
        },
      })
    },
  }
}
