export default defineNuxtPlugin({
  name: "Matomo",
  async setup() {
    const { data: jobShop } = useNuxtData("jobShopData")
    const script = document.createElement("script")
    const useMatomo = jobShop.value?.externalScriptSettings.isCookielessTrackingEnabled

    if (useMatomo) {
      script.innerHTML = `
       matomoEnabled = true;
      
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['setCustomDimension', 1, '${jobShop.value.jobShopId}']);
       
        _paq.push(["setDoNotTrack", true]);
        // Call disableCookies before calling trackPageView
        _paq.push(['disableCookies']);
        _paq.push(['enableLinkTracking']);
        (function() {
          var u="${jobShop.value.externalScriptSettings.matomoUrl}";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '${jobShop.value.externalScriptSettings.matomoSiteId}']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
      `
      document.head.appendChild(script)
    }
  },
})
