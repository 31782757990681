export function useHeaderStore() {
  const headerStore = useState<HeaderStore>("headerStore", () => ({
    tenantId: "",
    jobShopId: "",
    jobShopUrl: "",
    acceptLanguage: "",
  }))

  const setHeaderStore = (newHeaderStore: HeaderStore) => {
    headerStore.value = newHeaderStore
  }

  const tenantId = headerStore.value.tenantId
  const jobShopId = headerStore.value.jobShopId
  const jobShopUrl = headerStore.value.jobShopUrl
  const acceptLanguage = headerStore.value.acceptLanguage

  return {
    headerStore,
    setHeaderStore,
    tenantId,
    jobShopId,
    jobShopUrl,
    acceptLanguage,
  }
}
