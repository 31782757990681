import { pageRepository } from "~/utils/repositories/page"
import { contentRepository } from "~/utils/repositories/content"
import { jobShopRepository } from "~/utils/repositories/jobshop"
import { searchAndFiltersRepository } from "~/utils/repositories/searchAndFilters"

/**
 * Provides the api repositories created in ~/utils/repositories as a plugin.
 * The plugin is usable via $api.{repository} when imported from useNuxtApp().
 */
export default defineNuxtPlugin((nuxtApp) => {
  const { app: { baseURL } } = useRuntimeConfig()

  // Add general, additional config to the fetch instance used for the api repositories
  const api = $fetch.create({
    baseURL,
  })

  // Add new repositories here
  const repositories = {
    page: pageRepository(api),
    content: contentRepository(api),
    jobShop: jobShopRepository(api),
    searchAndFilters: searchAndFiltersRepository(api),
  }

  return {
    provide: {
      api: repositories,
    },
  }
})
