import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin({
  name: "GTM",
  dependsOn: ["CookieConsent", "CCM19"],
  async setup(nuxtApp) {
    /**
     * This appends the standard GTM script to the body
     * and enables the GTM Vue plugin.
     *
     * GTM itself might not be enabled really,
     * because CCM19 might have blocked it still
     */
    const { public: { gtm: { id } } } = useRuntimeConfig()
    const { data: jobShop } = useNuxtData("jobShopData")
    const { $cookieConsent } = useNuxtApp()

    const useMatomo = jobShop.value?.externalScriptSettings.isCookielessTrackingEnabled

    if (!useMatomo) {
      nuxtApp.vueApp.use(createGtm({
        id,
        enabled: false,
        loadScript: false,
        debug: import.meta.dev,
        trackViewEventProperty: "jobfinderView",
      }))

      // Append comment before GTM script
      const startComment = document.createComment(" GTM Standard Script Start ")
      document.body.appendChild(startComment.cloneNode())

      // Create the noscript iframe for GTM
      const noscript = document.createElement("noscript")
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`

      // Append the noscript to the body
      document.body.appendChild(noscript)

      // Create the GTM script element
      const script = document.createElement("script")
      script.innerHTML = `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer'
              ? '&l=' + l
              : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${id}');
      `

      // Append the script to the head
      document.body.appendChild(script)

      // Append comment after GTM script
      const endComment = document.createComment(" GTM Standard Script End ")
      document.body.appendChild(endComment.cloneNode())

      function activatePlugin() {
        useGtm()?.enable(true)
      }

      // Check consent status whenever it changes
      watch($cookieConsent.checkConsent, (hasConsent) => {
        if (hasConsent)
          activatePlugin()
      }, { immediate: true })
    }
  },
})
