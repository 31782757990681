import { consola } from "consola"
import { formatInTimeZone } from "date-fns-tz"

// Match format of logged time by our Java services
function getBerlinTime() {
  const now = new Date()
  const timeZone = "Europe/Berlin"
  const pattern = "yyyy-MM-dd HH:mm:ss.SSS"
  return formatInTimeZone(now, timeZone, pattern)
}

/**
 * Returns a consolas instance and can be used to log to the console
 * Supports all consola methods and message types (e.g. .error(), .success(), .start(), etc.)
 * @param requestId optional requestId to be included
 * @returns ConsolaInstance
 */
export default function Logger(requestId?: string) {
  const dateTime = getBerlinTime()

  const tag = requestId ? `${dateTime} | ${requestId}` : `${dateTime}`
  return consola.withTag(tag)
}
