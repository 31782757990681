import * as Sentry from "@sentry/vue"
import { name, version } from "../package.json"

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()

  if (!sentry.dsn)
    return

  if (import.meta.dev) {
    console.warn("Sentry will not be initialized during development mode")
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: `${name}@${version}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.thirdPartyErrorFilterIntegration({
        // Specify the application keys that you specified in the Sentry bundler plugin
        filterKeys: ["candidate-frontend"],

        // Defines how to handle errors that contain third party stack frames.
        behaviour: "drop-error-if-contains-third-party-frames",
      }),
    ],

    tracesSampleRate: 0.2,

    // Add Sentry's trace headers to all requests going to localhost and URLs containing job-shop.com or jobshop.dev
    tracePropagationTargets: ["localhost", /(job-shop\.com|jobshop\.dev)/],
  })
})
