import * as amplitude from "@amplitude/analytics-browser"
import { userAgentEnrichmentPlugin } from "@amplitude/plugin-user-agent-enrichment-browser"

export default defineNuxtPlugin({
  name: "Amplitude",
  dependsOn: ["CCM19"],
  async setup(nuxtApp) {
    const { public: { amplitudeApi } } = useRuntimeConfig()
    const amplitudeApiKey = amplitudeApi.key
    const cookieName = "amp_*"

    const uaPlugin = userAgentEnrichmentPlugin({
      osName: true,
      osVersion: true,
      deviceManufacturer: true,
      deviceModel: true,
    })
    amplitude.add(uaPlugin)

    function activateIfConsentGiven() {
      if (CCM.acceptedCookies.includes(cookieName)) {
        amplitude.init(amplitudeApiKey, undefined, {
          serverZone: "EU",
          serverZoneBasedApi: true,
          defaultTracking: true,
          transport: "beacon", // send in background, even if user leaves
          flushIntervalMillis: 0, // directly
          flushQueueSize: 1,
          trackingOptions: {
            ipAddress: false,
            language: true,
            platform: false,
          },
        })
        amplitude.setOptOut(false)
      }
      else {
        amplitude.setOptOut(true)
      }
    }

    // initalize after cookie consent given
    window.addEventListener("ccm19WidgetClosed", () => {
      activateIfConsentGiven()
    })

    // or if there still is consent from before
    window.addEventListener("ccm19WidgetLoaded", () => {
      activateIfConsentGiven()
    })

    return {
      provide: {
        amplitude,
      },
    }
  },
})
