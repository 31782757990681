// part of nuxt
import type { $Fetch, NitroFetchRequest } from "nitropack"

export function jobShopRepository<T>(fetch: $Fetch<T, NitroFetchRequest>) {
  return {
    /**
     * Returns data about the current JobShop incl. global settings
     * @param tenantId targeted tenant
     * @param jobShopId id of the JobShop from which data is requested
     * @param locale language locale for the Accept-Language header
     * @returns
     */
    async getJobShopData(tenantId: string, jobShopId: string, locale: string): Promise<JobShop> {
      return fetch<JobShop>(`${UrlConfig.PATH_BASE_CONTENT_SERVICE}/public/jobshop`, {
        headers: {
          "Accept-Language": locale,
          "Accept": "application/json",
          "X-Tenant-Id": tenantId,
          "X-JobShop-Id": jobShopId,
        },
      })
    },
  }
}
