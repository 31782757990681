import type { RouterConfig } from "@nuxt/schema"
import qs from "qs"
import Logger from "@/utils/Logger"

export default <RouterConfig>{
  routes: (_routes) => {
    const { ssrContext } = useNuxtApp()
    const customPath = useCustomPath()

    // Get the path of the JobShop from the event context, prefilled by the custom-path server middleware
    if (ssrContext?.event.context.customPath)
      customPath.value = ssrContext?.event.context.customPath

    // Map over the existing routes and prepend the customPath, if it exists
    if (customPath.value) {
      if (process.server)
        Logger().log(`Prepending ${customPath.value} to all existing routes`)

      return _routes.map(route => ({
        ...route,
        path: customPath.value + route.path,
      }))
    }
  },
  // To ensure nuxt router understands deeply nested queries objects from typesense using qs
  parseQuery: qs.parse,
  stringifyQuery: query => qs.stringify(query, {
    addQueryPrefix: false,
    arrayFormat: "repeat",
    format: "RFC3986",
  }),
}
