import { ref } from "vue"

function isOneTrustActive(): boolean {
  return typeof window !== "undefined" && typeof window.OptanonWrapper === "function" && window.OnetrustActiveGroups
}

function isCCM19Active(): boolean {
  return typeof window !== "undefined" && typeof CCM !== "undefined"
}

const oneTrustPerformanceCookiesGroup = "C0002"
const consentGranted = ref(false)

export function useCookieConsent() {
  const checkOneTrustConsent = () => {
    if (!isOneTrustActive())
      return

    // Check if group C0002 is accepted (represents performance cookies)
    if (window.OnetrustActiveGroups.includes(oneTrustPerformanceCookiesGroup))
      consentGranted.value = true
  }

  const initializeConsentListeners = () => {
    // CCM19 Events
    window.addEventListener("ccm19WidgetClosed", () => {
      consentGranted.value = true
    })

    window.addEventListener("ccm19WidgetLoaded", () => {
      consentGranted.value = true
    })

    // OneTrust Events
    window.addEventListener("OneTrustGroupsUpdated", checkOneTrustConsent)
  }

  // Initialize listeners and check initial OneTrust state
  if (typeof window !== "undefined") {
    initializeConsentListeners()
    // Check if OneTrust is already initialized
    if (isOneTrustActive())
      checkOneTrustConsent()
  }

  return {
    checkConsent: () => consentGranted.value,
    openCookieSettings: () => {
      if (isCCM19Active())
        CCM.openWidget()
      else if (isOneTrustActive())
        window.OneTrust?.ToggleInfoDisplay()
    },
  }
}

export default defineNuxtPlugin({
  name: "CookieConsent",
  enforce: "pre",
  setup() {
    return {
      provide: {
        cookieConsent: useCookieConsent(),
      },
    }
  },
})
