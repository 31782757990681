/**
 * Appends the provided html string to the provided target element.
 * Iterates over the included nodes and handles them accordingly (script vs. noscript vs. everything else).
 * @param target HTML element where the html string should be inserted
 * @param htmlString string to be inserted
 */
function appendHtmlString(target: HTMLElement, htmlString: string) {
  // Parse our provided htmlString into actual HTML nodes by creating a temporary div and set innerHTML to the htmlString
  const tempDiv = document.createElement("div")
  tempDiv.innerHTML = htmlString

  // Loop over the child nodes of the temporary div
  while (tempDiv.firstChild) {
    const node = tempDiv.firstChild as HTMLElement
    // remove processed node from temporary div
    tempDiv.removeChild(node)

    if (node.tagName === "SCRIPT") {
      const scriptNode = node as HTMLScriptElement
      const newScript = document.createElement("script")

      // Copy all attributes from the original scriptNode to the newScript
      for (let i = 0; i < scriptNode.attributes.length; i++) {
        const attr = scriptNode.attributes[i]
        newScript.setAttribute(attr.name, attr.value)
      }

      if (scriptNode.src)
        newScript.src = scriptNode.src
      else
        newScript.textContent = scriptNode.textContent

      target.appendChild(newScript)
    }
    else if (node.tagName === "NOSCRIPT") {
      const newNoscript = document.createElement("noscript")
      newNoscript.innerHTML = node.innerHTML
      target.appendChild(newNoscript)
    }
    else {
      target.appendChild(node)
    }
  }
}

/**
 * Checks if the provided html string contains the standard GTM id
 * @param htmlString string to check
 * @param standardGtmId id of the standard GTM
 */
function isStandardGtmScript(htmlString: string, standardGtmId: string) {
  return htmlString.includes(standardGtmId)
}

export default defineNuxtPlugin({
  name: "ThirdPartyIntegrations",
  setup() {
    const { public: { gtm: { id } } } = useRuntimeConfig()
    const { data: jobShop } = useNuxtData("jobShopData")
    const thirdPartyIntegrations: ThirdPartyIntegration[] = jobShop.value?.thirdPartyIntegrations

    if (thirdPartyIntegrations && thirdPartyIntegrations.length > 0) {
      thirdPartyIntegrations.forEach((integration) => {
        const sourceCodeHead = integration.sourceCodeHead
        const sourceCodeBody = integration.sourceCodeBody

        // Only add if source code exists and if it is not the script of the standard GTM
        if (sourceCodeHead && !isStandardGtmScript(sourceCodeHead, id))
          appendHtmlString(document.head, sourceCodeHead)

        if (sourceCodeBody && !isStandardGtmScript(sourceCodeBody, id))
          appendHtmlString(document.body, sourceCodeBody)
      })
    }
  },
})
