import type { DirectiveBinding } from "vue"
import Logger from "~/utils/Logger"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("append-parameters", {
    beforeMount: appendParameters,
    updated: appendParameters,
  })
})

function appendParameters(el: HTMLElement, binding: DirectiveBinding<QueryHeader[]>) {
  // Get all <a> tags & filter them
  const links = Array.from(el.querySelectorAll("a")).filter((linkEl) => {
    const href = linkEl.getAttribute("href")
    // Exclude <a> tags without href and with already appended parameters
    if (!href || includesQueryHeaders(binding.value, href))
      return false

    // Only return external links
    return !isInternalLink(href)
  })

  // Append parameters to href of filtered <a> tags
  links.forEach((link) => {
    try {
      // Cast as string since we know the href won't be empty
      const href = new URL(link.getAttribute("href") as string)
      const params = new URLSearchParams(href.search)

      binding.value.forEach((queryHeader) => {
        params.set(queryHeader.key, queryHeader.value)
      })

      href.search = params.toString()
      link.setAttribute("href", href.toString())
    }
    catch (e) {
      Logger().warn(`Invalid URL used "${link}"`, e)
    }
  })
}

function isInternalLink(href?: string) {
  const { jobShopUrl } = useHeaderStore()
  return href?.startsWith(jobShopUrl) || href?.startsWith("#") || href?.startsWith("/")
}

function includesQueryHeaders(queryHeaders: QueryHeader[], url: string): boolean {
  const params = new URLSearchParams()

  queryHeaders.forEach((queryHeader) => {
    params.set(queryHeader.key, queryHeader.value)
  })

  return url.includes(params.toString())
}
