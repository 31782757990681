// part of nuxt
import type { $Fetch, NitroFetchRequest } from "nitropack"

export function pageRepository<T>(fetch: $Fetch<T, NitroFetchRequest>) {
  return {
    /**
     * Returns general page details
     * @param tenantId targeted tenant
     * @param jobShopId id of the JobShop from which the page is part of
     * @param locale language locale for the Accept-Language header
     * @param pageSlug slug of the page to be requested
     * @returns
     */
    async getPageBySlug(tenantId: string, jobShopId: string, locale: string, pageSlug?: string): Promise<Page> {
      return fetch<Page>(`${UrlConfig.PATH_BASE_CONTENT_SERVICE}/public/pages`, {
        headers: {
          "Accept-Language": locale,
          "Accept": "application/json",
          "X-Tenant-Id": tenantId,
          "X-JobShop-Id": jobShopId,
        },
        params: {
          page_slug: pageSlug,
        },
      })
    },
  }
}
